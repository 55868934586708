<template>
  <v-combobox
    :items="volunteers"
    :placeholder="placeholder"
    item-text="name"
    item-value="guid"
    :value="value"
    clearable
    :return-object="true"
    color="rgba(0,0,0,.6)"
    :class="classList"
    outlined
    single-line
    hide-details
    dense
    @input="updateValue"
  >
  </v-combobox>
</template>
<script>
export default {
  name: "volunteer-select",
  props: {
    placeholder: {
      type: String,
      default: "Volunteer",
    },
    value: {
      type: Object,
    },
    classList: {
      type: String,
    },
  },
  data() {
    return {
      volunteers: [],
    };
  },
  mounted() {
    this.getVolunteersList();
  },
  methods: {
    updateValue: function (value) {
      this.$emit("input", value);
    },
    getVolunteersList: function () {
      fetch(process.env.VUE_APP_API_URL + "/users/volunteers/autocomplete", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": this.authToken,
          "Accept-Language": this.$store.getters.getLocale,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.type !== "success") {
            this.localAlert = {
              type: "error",
              color: "red",
              message: data.message
                ? data.message
                : this.$translator.trans("Fetching volunteers list failed!"),
            };

            return false;
          }

          this.volunteers = data.volunteers;
        })
        .catch((error) => {
          console.error("Error:", error);

          this.localAlert = {
            type: "error",
            color: "red",
            message: error.message
              ? error.message
              : this.$translator.trans("Fetching volunteers list failed!"),
          };
        });
    },
  },
  computed: {
    authToken() {
      return this.$store.getters.getAuthToken;
    },
  },
};
</script>
